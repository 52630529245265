import "./about-us.scss";
import React from "react";
import imgAbout2x from "./img/img-about@2x.jpg";
import imgAboutDesktop2x from "./img/img-about-desktop@2x.jpg";

const AboutUs = () => (
	<>
		<section className="full-width about-us">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2 className="about-us__header">О нас</h2>
					</div>
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-12">
						<img
							className="hidden-md hidden-sm about-us__image_mobile"
							src={imgAbout2x}
							alt="Изображение транспортной карты"
						/>

						<img
							className="hidden-xs about-us__image_desktop"
							src={imgAboutDesktop2x}
							alt="Изображение транспортной карты"
						/>
					</div>

					<div className="col-xs-12 col-md-offset-3 col-md-6 col-sm-offset-1 col-sm-10">
						<p className="about-us__text">
							Автоматизированная система оплаты проезда в городском пассажирском
							транспорте (АСОП ГПТ г.Казань) предназначена для автоматизации
							контроля оплаты проезда в городском пассажирском транспорте на
							базе внедрения передовых информационных технологий и системной
							методологии. Проект реализуется АО «Транспортная карта» -
							оператором системы АСОП ГПТ г.Казань, учредителями которой
							являются ПАО «АК БАРС» БАНК и Комитет земельных и имущественных
							отношений Исполнительного комитета муниципального образования
							г.Казани.
						</p>

						<h4 className="about-us__title">Система решает задачи</h4>

						<ul className="about-us__list">
							<li>
								— Обеспечение реального контроля прохождения и расходования
								бюджетных средств, выделяемых в рамках социальных программ и для
								обеспечения жизнедеятельности транспортных предприятий;
							</li>
							<li>
								— Обеспечение денежных компенсаций транспортным операторам за
								реальный объем оказанных услуг; обеспечение адресной социальной
								помощи населению в части расходов на транспорт;
							</li>
							<li>
								— Возможное использование многофункциональной карты как
								инструмента расчетов за транспортные услуги и носителя других
								социальных, банковских и финансовых приложений
								(идентификационная карта личности и категорий социальных льгот,
								для организации оплаты жилищно-коммунальных услуг, адресной
								социальной помощи, оплаты услуг медицинских учреждений и
								страховых компаний, продажи лекарств, товаров народного
								потребления и т.п.);
							</li>
							<li>
								— Повышение защищенности проездных документов от незаконного
								использования и воспроизведения; возможность получения реальных
								данных о распределении пассажиропотока, способствующих
								оптимизации планирования маршрутной сети и количества подвижного
								состава.
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	</>
);

export default AboutUs;
